export default app => {
    app.directive('outsideclick', {
        beforeMount(el, binding) {
            function clickHandler(e) {
                if (el.contains(e.target)) {
                    return false;
                }
                if(e.outSideIgnore && e.outSideIgnore == binding.value){
                    return ;
                }
                if (binding.value) {
                    binding.value(e);
                }
            }
            el.__vueClickOutside__ = clickHandler;
            document.addEventListener('click', clickHandler, false);
        },
        unmounted(el) {
            document.removeEventListener('click', el.__vueClickOutside__);
            delete el.__vueClickOutside__;
        }
    });
    app.directive('outsidetouchstart', {
        beforeMount(el, binding) {
            function clickHandler(e) {
                if (el.contains(e.target)) {
                    return false;
                }
                if (binding.value) {
                    binding.value(e);
                }
            }
            el.__vueClickOutsideTouch__ = clickHandler;
            document.addEventListener('touchstart', clickHandler, false);
        },
        unmounted(el) {
            document.removeEventListener(
                'touchstart',
                el.__vueClickOutsideTouch__
            );
            delete el.__vueClickOutsideTouch__;
        }
    });
};
