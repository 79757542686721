import util from '@/common/util';
import dirty from '@/dirty';

export default function (globalData, { platform }) {
    try {
        dirty({ globalData });

        //  Remove the parent window of the details page pop-up window loading
        if (util.inPopDetailPage) {
            window.parent.__curDetailPop.setLoading(false);
        }
    } catch (e) {}

    if (window.siteAppVm) {
        window.siteAppVm.$EventBus.$emit('siteTrack', {
            trackType: 'page-change'
        });
    }
    if (util.inCms) {
        document.body.classList.add(globalData.template);
    }
    if (platform.isMobile) {
        document.body.classList.add('mobile');
    }
    if (globalData.pageKey) {
        document.body.classList.add(globalData.pageKey);
    }
    if (globalData.isLandingPage) {
        document.body.classList.add('landing-page');
    }
    if (globalData.isTestSite) {
        document.body.classList.add('test-site');
    }
    if (globalData.siteStatus === 0) {
        document.body.classList.add('pending');
    }

    if (util.isReallyUser) {
        let requestId = document.querySelector('#requestId');
        if (requestId) {
            // crm traffic report  Statistical data
            new Image().src = '/0.gif?u=' + (requestId.value || '');
        }
    }
}
