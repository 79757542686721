import FingerprintJS from '@fingerprintjs/fingerprintjs';
import uuidv4 from 'uuidv4';
import util from './util';

let fp;
const STORAGE_KEY_FINGERPRINT = '_chime_fingerprint';

const fallback = () => ({
    visitorId: uuidv4(),
    fallback: true
});

const getFingerprint = config => {
    const rawFingerprint = util.getStorageItem(
        'localStorage',
        STORAGE_KEY_FINGERPRINT
    );
    const defValue = config?.fallback ? fallback() : null;
    try {
        if (rawFingerprint) {
            const fingerprint = JSON.parse(rawFingerprint);
            return fingerprint;
        }
    } catch (error) {
        return defValue;
    }
    return defValue;
};

const generateFingerprint = async () => {
    let fingerprint;
    try {
        fingerprint = fp ? await fp.get() : fallback();
    } catch (error) {
        if (process.env.CHIME_ENV === 'development') {
            console.error(error);
        }
        fingerprint = fallback();
    }
    return fingerprint;
};

export const initFingerprint = async () => {
    try {
        fp = await FingerprintJS.load({
            monitoring: false
        });
    } catch (error) {
        if (process.env.CHIME_ENV === 'development') {
            console.error(error);
        }
    }

    const fingerprint = getFingerprint();

    if (!fingerprint || fingerprint?.fallback) {
        const newFingerprint = await generateFingerprint();
        util.setStorageItem(
            'localStorage',
            STORAGE_KEY_FINGERPRINT,
            JSON.stringify(newFingerprint)
        );
    }

};

export const getDeviceId = () => {
    let fingerprint = getFingerprint({ fallback: true });
    const deviceId = fingerprint.visitorId;
    return { deviceId };
};
