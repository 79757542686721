import uuidv4 from 'uuidv4';
import { inPopDetailPage, utilTool, getQueryField } from '@/common/utils';
import { isSSRServer } from '@/config';
import modulesMap from '../../modules/module-map';
import Observer from '@/common/Observer';

const globalData = {};

//  according to  globalData.pageKey  renew
let SELLER_PAGE_KEY = ['listing-detail-sold', 'sell', 'evaluation'];
function updateRegisterTypeByPageKey(pageKey) {
    if (SELLER_PAGE_KEY.includes(pageKey)) {
        console.log('--updateRegisterTypeByPageKey----SELLER--');
        globalData.registerUserType = 'SELLER';
    }
}

function removeHotListingByMls(moduleData, globalData) {
    let LISTING_DETAIL = ['listing-detail-sold', 'listing-detail'];
    let pageKey = globalData.pageKey || '';
    let mlsOrgs = globalData.mlsOrgs || [];
    if (mlsOrgs.length > 0 && LISTING_DETAIL.includes(pageKey)) {
        let showHotListings =
            mlsOrgs[0].showHotListings === undefined
                ? true
                : mlsOrgs[0].showHotListings;
        if (!showHotListings) {
            return moduleData.filter(item => {
                return !(
                    item.name == 'md-house-listing' && item.data.type == 'hot'
                );
            });
        }
    }

    return moduleData;
}

const prepareData = function (data) {
    if (data === 'fetch') {
        return globalData;
    }
    for (let key in globalData) {
        if (globalData.hasOwnProperty(key)) {
            delete globalData[key];
        }
    }
    //  Add anchor configuration
    const defaultGlobalData = {
        vue3: true,
        registerUserType: 'BUYER',
        template: getQueryField('template') || 'standard',
        registration: {
            style: 'standard'
        },
        questionNaire: {
            style: 'standard'
        },
        themeColor: '#03c9ff',
        hereApiKey: 'ossFwZuLtG6UBfU1nBYfnST0x6XhkGbLko7JKOhLYAA',
        scheduleAnchor: {
            name: 'schedule',
            loginCallback: null
        }
    };
    Object.assign(globalData, defaultGlobalData, data);

    if (globalData.rulesMap) {
        //change attribute name
        globalData.terminologyMap = globalData.rulesMap;
        delete globalData.terminologyMap;
    }
    globalData.template =
        (globalData.themeConfig || {}).templateValue || globalData.template;
    globalData.is_virtual_lead = utilTool.getCookie('is_virtual_lead')
        ? true
        : false;
    let thirdPartAuthor = globalData.thirdPartAuthor || {},
        fbid = thirdPartAuthor.facebookClientId,
        ggid = thirdPartAuthor.googleClientId;
    if (fbid && fbid != 0) {
        globalData.facebook = true;
        globalData.FBAppId = fbid;
    } else {
        globalData.facebook = false;
    }
    if (ggid && ggid != 0) {
        globalData.google = true;
        globalData.googleAppId = ggid;
    } else {
        globalData.google = false;
    }
    globalData.licenseName = globalData.licenseName || 'License ID: ';
    if (utilTool.isObject(globalData.lazyMode)) {
        globalData.lazyMode = globalData.lazyMode.lazyMode;
    }
    if (inPopDetailPage || globalData.lazyMode === undefined) {
        globalData.lazyMode = false;
    }

    //resolve track
    if (globalData.hasOwnProperty('lpTrack')) {
        if (globalData.lpTrack.ga) {
            globalData.track.ga = globalData.lpTrack.ga;
        }
        if (globalData.lpTrack.pixel) {
            globalData.track.pixel = globalData.lpTrack.pixel;
        }
    }
    if (globalData.map) {
        globalData.map.type = globalData.map.type || 'here';
    } else {
        globalData.map = {
            type: 'here'
        };
    }
    globalData.house = globalData.house || {};
    if (globalData.house.showListingPrice === undefined) {
        globalData.house.showListingPrice = globalData.soldPrice
            ? globalData.soldPrice.soldListingPriceMark == 1
            : true;
    }
    if (globalData.house.showSoldPrice === undefined) {
        globalData.house.showSoldPrice = globalData.soldPrice
            ? globalData.soldPrice.soldListingPriceMark == 1
            : true;
        if (
            globalData.soldPrice &&
            globalData.soldPrice.soldListingPriceMark == 2
        ) {
            globalData.house.showSoldPrice = true;
        }
    }
    if (globalData.house.showAgentName === undefined) {
        globalData.house.showAgentName = globalData.hideAgentName
            ? !globalData.hideAgentName.hideAgentName
            : true;
    }
    //  Users registered on the details page are seller
    // if (globalData.pageKey === 'listing-detail-sold') {
    //     globalData.registerUserType = 'SELLER';
    // }
    updateRegisterTypeByPageKey(globalData.pageKey);

    if (!isSSRServer) {
        window.addEventListener('message', ({ data }) => {
            if (data.action === 'site_global_data') {
                Object.assign(globalData, data.data);
            }
        });
    }
    if (
        globalData.openMultilingual &&
        globalData.sysLanguageList &&
        globalData.sysLanguageList.length > 1
    ) {
        globalData.translatorType = 'chime';
    } else if (globalData.language && globalData.language.show) {
        globalData.translatorType = 'googlePlugin';
    }

    // globalData.wordPressSSR = true;
    // globalData.widget = false;

    return globalData;
};

// 根据purchaseType来修改 registerUserType (in md-detail-info created)
function updateRegisterUserType(globalData, purchaseType) {
    // 记录registerUserType是否是通过purchaseType来设置的
    globalData.isRegUserTypeByPurchaseType = true;
    switch (purchaseType) {
        case 'For Sale':
            globalData.registerUserType = 'BUYER';
            break;
        case 'For Rent':
            globalData.registerUserType = 'RENTAL';
            break;
        case 'For Sold':
            globalData.registerUserType = 'SELLER';
            break;
        default:
            break;
    }
}

// 处理全局数据
function handleGlobalData(rawGlobalData, themeRootVar) {
    if (rawGlobalData.agentCreator) {
        rawGlobalData.agentCreator.headUrl =
            rawGlobalData.agentCreator.image ||
            rawGlobalData.agentCreator.headUrl;
    }
    let { themeColor } = themeRootVar;
    delete themeRootVar.themeColor;
    return prepareData(
        Object.assign(rawGlobalData, {
            themeColor,
            themeRootVar
        })
    );
}

const houseThemeMap = {
    grid: 'grid-v3',
    'grid-': 'grid-v3',
    'grid-template1': 'grid-v1',
    'grid-standard': 'grid-v2',
    'grid-template4': 'grid-v3',
    list: 'list-v2',
    'list-': 'list-v2',
    'list-template1': 'list-v1',
    'list-standard': 'list-v2',
    'list-template4': 'list-v3'
};

// function remove$sysAttrs(target){
//     if (target){
//         delete target.$sys_draggable;
//         delete target.$sys_topdrag;
//         delete target.$sys_editable;
//         delete target.$sys_deletable;
//         delete target.$sys_codeable;
//         delete target.$sys_btmdrag;
//         delete target.$sys_showImageEditor;
//         delete target.$sys_showPresetFilter;
//     }
// }

export const processModuleData = (item, globalData) => {
    // layout不做处理, 但是要处理items
    if (item.items) {
        item.items.forEach(it => processModuleData(it, globalData));
        return item;
    }

    if (item.vdata) {
        return item;
    }
    if (!item.data) {
        item.data = {};
    }

    let id = item.id || uuidv4();
    let theme;
    if (
        utilTool.isMobile &&
        item.data.mobileTheme &&
        modulesMap[item?.name]?.[item?.data?.mobileTheme]
    ) {
        theme = item.data.mobileTheme;
    } else {
        theme =
            item.data.theme ||
            (item.commonData && item.commonData.theme) ||
            'standard';
    }
    let itemData = Object.assign({}, item.commonData || {}, item.data || {});
    if (item.name === 'md-house' && ['grid', 'list'].includes(theme)) {
        theme =
            houseThemeMap[theme + '-' + (itemData.style || '')] ||
            houseThemeMap[theme];
        itemData.theme = theme;
        itemData.style = '';
    }
    // 非landing page的情况下 更新globalData的registerUserType
    if (!globalData.isLandingpage && item.name === 'md-detail-info') {
        let type;
        if (
            (type = utilTool.getValueByPath(
                item,
                'data.listingDetail.info.purchaseType'
            ))
        ) {
            updateRegisterUserType(globalData, type);
        }
    }
    Object.assign(item, {
        id: id,
        module: true,
        visible: item.visible || false,
        isBlockRoot: true,
        moduleLoad: false,
        moduleRender: false,
        moduleMounted: false,
        loadingPs: null,
        noObserve: globalData.lazyMode === false || itemData.noObserve,
        theme: theme,
        componentName: item.name + '-' + theme,
        // vdata: {
        //     id: id,
        //     key: id
        // },
        // getVdata(self, item) {
        //     return { ...item.vdata };
        // },
        blockOb: Object.freeze(new Observer()),
        data: itemData,
        vm: null,
        i18next: {
            ns: ''
        }
    });

    return item;
};

// 处理模块数据
export const processModuleListData = (pageData, globalData, query) => {
    var data = pageData.moduleData || pageData.modules;
    if (query?.disableMds) {
        let idxs = query['disableMds'].split(',');
        data = data.filter((item, index) => {
            return idxs.indexOf(index.toString()) === -1;
        });
    }
    data.forEach(item => processModuleData(item, globalData));
    return data;
};

// 处理页面数据
function handlePageData(rawPageData, globalData, query) {
    var moduleData = processModuleListData(rawPageData, globalData, query);
    moduleData = removeHotListingByMls(moduleData, globalData);
    return {
        moduleData,
        css: rawPageData.css,
        script: rawPageData.script || rawPageData.js,
        page: rawPageData.page
    };
}

// 解析rootVal
function getRootVal(style) {
    let reg = /(--[\w-]+):\s*([^;}]*)/gm;
    let varWhiteList = [
        '--color-theme',
        '--mg-title-size',
        '--mg-sub-title-size',
        '--g-btn-background'
    ];
    let blockMap = {};
    if(!style){
        return blockMap;
    }
    style.replace(reg, function (match, $1, $2) {
        if (varWhiteList.includes($1)) {
            blockMap[$1] = $2 === 'null' ? '' : $2.replace(/'|"/g, '').trim();
        }
    });
    return blockMap;
}

/**
 * 处理原始数据, 返回前端使用的数据
 * @param {*} rawGlobalData 全局的原始数据
 * @param {*} rawPageData 页面的原始数据
 * @param {*} themeColor 主题色
 */
export default function handleData({
    rawGlobalData,
    rawPageData,
    theme = '',
    themeRootVar,
    query
}) {
    if (!themeRootVar) {
        themeRootVar = Object.assign(
            { themeColor: '#03c9ff' },
            getRootVal(theme)
        );
    }
    var globalData = handleGlobalData(rawGlobalData, themeRootVar);
    globalData.moduleNoLazy = true;
    globalData.lazyMode = false;
    var pageData = handlePageData(rawPageData, globalData, query);
    return {
        globalData,
        pageData
    };
}
