import { Comment } from '@vue/runtime-core';
import mdEmpty from './md-empty.vue';
import mdError from './md-error.vue';
import moduleOperationButton from './module-operation-button.vue';
import util from '@/common/util';
import { h , cloneVNode} from 'vue';

let siteInfo = {};
let cmsVm = {};
let topUrl = '';

try{
    siteInfo = window.top.SiteInfo || {};
}catch(e){
// console.error(e);
}

try{
    topUrl =   window.top.location.href
}catch(e){

}
try{
    cmsVm = window.top.cmsAppVm || {};
}catch(e){
// console.error(e);
}

let isAdsDMA = false
if (topUrl) {
    isAdsDMA = util.getQueryField('from', topUrl) === 'ads' && util.getQueryField('page', topUrl) === 'landingPage'
}

let isSubSite_idx = siteInfo.isSubSite;
if(!topUrl){
    isSubSite_idx = false;
}else{
    // 排查 Landing page 页面
    let u = new URL(topUrl);
    isSubSite_idx = isSubSite_idx &&  /\/cmsnew\/?$/.test(u.pathname);
}



const initOperationButtonClass = (vnode, ctx) => {
    let $data =  new Proxy(ctx.mdData?.data || {},{
        get(target,key,receiver){
            if( Object.prototype.hasOwnProperty.call(target,key) ){
                return Reflect.get(target,key,receiver);
            }
            return Reflect.get(target,`$${key}`,receiver);
        }
    }) ;
     let   cls = [],
        modulename = ctx.mdData?.name||'';
      let  userRole = cmsVm.$USERROLE,
        forceShowCode = cmsVm.$SHOWCODE;
    //  sub station idx  Apart from  md-team,md-testimonial,md-header,md-hero These four modules   disable all others
    // subdomain ads DMA surport edit LP
   
    if (isSubSite_idx) {
        cls = [
            'disable-delete',
            'disable-draggable',
            'disable-codeable',
            'disable-topdrag',
            'disable-btmdrag'
        ];
        if (
            ![
                'md-team',
                'md-testimonial',
                'md-header',
                'md-hero'
                // 'md-footer' 525  And then on
            ].includes(modulename) && !isAdsDMA
        ) {
            cls.push('disable-edit');
        }
    } else {
        if (
            $data.sys_deletable === false ||
            (typeof $data.sys_deletable == 'number' &&
                $data.sys_deletable > userRole)
        ) {
            cls.push('disable-delete');
        }
        if (
            $data.sys_draggable === false ||
            (typeof $data.sys_draggable == 'number' &&
                $data.sys_draggable > userRole)
        ) {
            cls.push('disable-draggable');
        }
        if (
            ($data.sys_codeable === false || userRole < 16) &&
            !forceShowCode
        ) {
            cls.push('disable-codeable');
        }
        if ($data.sys_topdrag === false) {
            cls.push('disable-topdrag');
        }
        if ($data.sys_btmdrag === false) {
            cls.push('disable-btmdrag');
        }
    }

    if (
        $data.sys_editable === false ||
        (typeof $data.sys_editable == 'number' &&
            $data.sys_editable > userRole)
    ) {
        cls.push('disable-edit');
    }
    // 避免 props 污染
    ctx._cls = cls
    let props = {...vnode.props};
    let className = props.class || '';
    className = className.split(' ');
    props.class = Array.from( new Set([ ... className, ...cls ] ) ).join(' ') ;
    vnode.props = props;
};

// mixins 只处理一层,  md-header/template6
const proxyMixins = function(rawConfig,ctx){
    let mixins = rawConfig.mixins || [];
    for (let i = mixins.length-1;  i >= 0; i--){
        let mixin = mixins[i];
        if(mixin.render){
            proxyRender(mixin);
            return true;
        }
    }
}

const proxyBotton = function(vnode ,ctx){
    if(!vnode){
        return ; 
    }
    if(Array.isArray(vnode.children)){
        let children = vnode.children||[];
        for(let i = 0; i < children.length; i++){
            let child = children[i];
            if(child.type?.name == moduleOperationButton.name){
                return ;
            }
        }
        vnode.children.push(h(moduleOperationButton, { ctx }));
    }else if(vnode.type?.render){
        proxyRender(vnode.type,ctx);
    }else if(vnode.type?.mixins){
        proxyMixins(vnode.type,ctx);
    }
}

const proxyRender = function(rawConfig, ctx){
    if (!util.inCmsPageEditor) {
        return;
    }
    const render = rawConfig.render;
    rawConfig.render = function (instance, ...rest) {
        // 还原为自己的 render; 源码里面同样的组件，会复用 vnode， 例如 site-img 会反复使用，导致 render 出错
        rawConfig.render = render;
        let vnode = render.apply(instance, [instance,...rest]);
        vnode = cloneVNode(vnode);
        proxyBotton(vnode ,ctx);
        return vnode
    }
    rawConfig.render._rc = true;
}


export default rawConfig => {
    if (!util.inCmsPageEditor) {
        return;
    }

    // cms no Data
    const render = rawConfig.render;
    rawConfig.render = function (...arg) {
        let vnode 
        try{
            vnode = render.apply(rawConfig, arg);
            if (!vnode ||   vnode.type === Comment) {
                const emptyVNode = h(mdEmpty);
                // 是注释
                vnode = emptyVNode.type.render(...arg);
            }
        }catch(e){
            console.error(e);
            vnode =  h(mdError).type.render(...arg);
        }
        const ctx = arg[0];
        vnode = cloneVNode(vnode);
        proxyBotton(vnode,ctx);
        initOperationButtonClass(vnode, ctx);
        return vnode;
    };
    rawConfig.render._rc = true;
    // 需要代理 render
};
