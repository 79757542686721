<template>
    <div
        v-if="!isTitleEmpty"
        class="site-title"
        :class="align"
        :style="style"
        v-html="formatFontSize(title, size)"
    ></div>
</template>

<script>
import util from '@/common/util';
export default {
    name: 'site-title',
    props: {
        title: [String],
        size: {
            type: [Number, String],
            default: '--mg-title-size'
        },
        mbSize: {
            type: [Number, String],
            default: '--mg-title-size-mobile'
        },
        align: {
            validator: value =>
                ['left', 'right', 'center'].indexOf(value) !== -1,
            default: ''
        }
    },
    computed: {
        isTitleEmpty() {
            return util.isEmptyRichtext(this.title);
        },
        titleSize() {
            return this.platform.isMobile ? this.mbSize : this.size;
        },
        style() {
            return {
                'font-size':
                    typeof this.titleSize === 'number'
                        ? `${this.titleSize}px`
                        : `var(${this.titleSize})`
            };
        }
    }
};
</script>

<style lang="scss">
@import '../../../theme/media';

.site-title {
    font-family: var(--mg-title-font);
    color: var(--g-primary-color);
    text-align: var(--mg-title-align);
    line-height: var(--mg-title-line-height);
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
    &.left {
        text-align: left;
    }
    &.center {
        text-align: center;
    }
    &.right {
        text-align: right;
    }
    @include media-600 {
        line-height: var(--mg-title-line-height-mobile);
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0;
        line-height: inherit;
    }
}
</style>
