<template>
    <Teleport v-if="parentDom" :to="parentDom">
        <div class="cms-module-operation-button">
            <template v-if="!notAllowEdit">
                <div class="module-editor" :style="editorStyle" v-if="OPERATION_LIST.length">
                    <template v-for="(item, index) in OPERATION_LIST">
                        <i
                            :class="'iconfont icon-' + item.icon"
                            :key="index"
                            v-if="item.icon && (moreOperationList ? index < 5 : index < 6)"
                            :title="item.title"
                        ></i>
                    </template>
                    <div v-if="moreOperationList" class="more-operation">
                        <div
                            class="more-operation-list"
                            @mouseover="displayMore"
                            @mouseleave="disapearMore"
                        >
                            <span class="iconfont icon-more1"></span>
                            <ul class="more-list" v-if="showMore">
                                <li
                                    v-for="(opt, index) in moreOperationList"
                                    :key="index"
                                    :class="opt.className"
                                >
                                    <span
                                        :class="'iconfont icon-' + opt.icon"
                                    ></span>
                                    <span>{{ opt.text }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="add-block-editor" :style="addStyle" v-if="showAdd">
                    <div class="add-block-contain">
                        <div class="add-block">
                            <span class="iconfont icon-add"></span>
                        </div>
                        <div class="add-tips">
                            <div class="arrow"></div>
                            <div class="add-content">Add Block</div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="cms-module-mask" :style="maskStyle"></div>
        </div>
    </Teleport>
</template>
<script>
import util from '@/common/util';
import { Teleport } from 'vue';
import { OPERATION_LIST } from './operation-const';

export default {
    name: 'module-operation-button',
    props: {
        ctx: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    components: { Teleport },
    data: function () {
        let showPaste = false;
        try {
            showPaste = !!window.top.$store.state.clipboardContent;
        } catch (error) {
            console.error(error);
        }
        return {
            maskBound: {
                eleMarginBottom: 0,
                offsetHeight: 0
            },
            parentDom: null,
            showMore: false,
            showPaste
        };
    },
    computed: {
        SiteInfo() {
            try {
                return window.top.SiteInfo || {};
            } catch (e) {
                return {};
            }
        },
        moduleData() {
            return this.$root.moduleData || [];
        },
        idx({ mdData, moduleData }) {
            return util.indexOf(moduleData, mdData.id, 'id');
        },
        mdData({ ctx }) {
            return ctx.mdData || {};
        },
        isSearchBlow(mdData, ctx) {
            const name = mdData.name || '';
            return name == 'md-search' && ctx.hideBelow;
        },
        showUP({ idx, moduleData, mdData, SiteInfo }) {
            if (SiteInfo.isSubSite) {
                return false;
            }
            if (idx == 0) {
                return false;
            }
            const firstBlockName = moduleData[0]?.name || '';
            const hasHeader = firstBlockName.startsWith('md-header');
            if (hasHeader && idx == 1) {
                return false;
            }
            const name = mdData.name || '';

            if (name.startsWith('md-footer')) {
                return false;
            }

            return true;
        },
        showDown({ mdData, idx, moduleData, isSearchBlow, SiteInfo }) {
            const name = mdData.name || '';
            if (SiteInfo.isSubSite) {
                return false;
            }
            const blocksLen = moduleData.length;
            if (idx == blocksLen - 1) {
                return false;
            }
            const lastBlockName = moduleData[blocksLen - 1]?.name || '';
            const hasFooter = lastBlockName.startsWith('md-footer');
            if (hasFooter && idx == blocksLen - 2) {
                return false;
            }
            if (name.startsWith('md-header')) {
                return false;
            }
            if (isSearchBlow) {
                return false;
            }

            return true;
        },
        showAdd({ mdData, isSearchBlow, SiteInfo, idx, moduleData }) {
            const name = mdData.name || '';
            if (isSearchBlow) {
                return false;
            }
            if (
                SiteInfo.isSubSite ||
                SiteInfo.isHESite ||
                SiteInfo.isStartPackage ||
                name == 'copyright' ||
                name == 'md-sidebar'
            ) {
                return false;
            }

            const blocksLen = moduleData.length;
            if (idx == blocksLen - 1 && name.startsWith('md-footer')) {
                return false;
            }
            return true;
        },
        addStyle({ maskBound }) {
            // md-team-desc team-desc-simple special treatment
            const eleMarginBottom = maskBound.eleMarginBottom;
            return {
                bottom: `${-12 - eleMarginBottom}px`
            };
        },
        maskStyle({ maskBound }) {
            const { offsetHeight, eleMarginBottom } = maskBound;
            return {
                top: `${eleMarginBottom < 0 ? 0 : offsetHeight}px`,
                height: `calc(100% - ${offsetHeight + 4}px`
            };
        },
        editorStyle({ maskBound }) {
            const { offsetHeight, eleMarginBottom } = maskBound;
            let top = eleMarginBottom < 0 ? 0 : offsetHeight;

            return {
                top: `${top + 20}px`
            };
        },
        notAllowEdit() {
            const notAllowEditPages = [
                'featured-area-market',
                'featured-area-search'
            ];
            try {
                return notAllowEditPages.includes(
                    window.top.$store.state.page('key')
                );
            } catch (error) {
                return false;
            }
        },
        moreOperationList({ OPERATION_LIST }) {
            if (OPERATION_LIST.length > 6) {
                return OPERATION_LIST.slice(5, OPERATION_LIST.length);
            }
            return false;
        },
        OPERATION_LIST
    },
    mounted() {
        const parentNode = this.$el.parentNode;
        if (parentNode) {
            parentNode.addEventListener('mouseenter', this.mouseEnter);
        }
        try {
            window.top.ClipboardSingleton.getInstance().addListener(
                'module_copy',
                () => {
                    this.showPaste = true;
                }
            );
        } catch (error) {
            console.error(error);
        }
    },
    beforeDestroy() {
        if (this.$el.parentNode) {
            this.$el.parentNode.removeEventListener(
                'mouseenter',
                this.mouseEnter
            );
        }
    },
    methods: {
        findTarget(ele) {
            if (ele && ele.offsetHeight === 0) {
                return this.findTarget(ele.children[0]);
            }
            return ele;
        },
        mouseEnter() {
            const ele =
                this.findTarget(this.$el.parentNode) || this.$el.parentNode;
            this.parentDom = ele;
            let eleMarginBottom = 0;
            let offsetHeight = 0;
            if (!ele) {
                return (this.maskBound = {
                    eleMarginBottom,
                    offsetHeight
                });
            }
            let prevEl = ele?.previousElementSibling;
            if (
                isPositionFixed(prevEl) ||
                isPositionFixed(prevEl?.children[0])
            ) {
                offsetHeight =
                    prevEl.offsetHeight || prevEl.children[0].offsetHeight;
                if (prevEl.children?.[1]) {
                    offsetHeight -= prevEl.children[1].offsetHeight;
                }
            }
            if (ele.dataset.margin) {
                eleMarginBottom = parseInt(ele.dataset.margin);
                if (eleMarginBottom < 0) {
                    offsetHeight -= eleMarginBottom;
                }
            }
            return (this.maskBound = {
                eleMarginBottom,
                offsetHeight
            });
        },
        disapearMore() {
            this.timer = setTimeout(() => {
                this.showMore = false;
            }, 500);
        },
        displayMore() {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.showMore = true;
        }
    }
};
const isPositionFixed = el => el && getComputedStyle(el).position === 'fixed';
</script>
<style lang="scss">
.module-selected {
    position: relative;
    overflow: visible !important;
    .cms-module-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border: solid 2px var(--primary-color);
        background-color: var(--primary-transparent-20);
        z-index: 5000;
    }
    .cms-module-operation-button{
        font-family: font-normal, font-light, Helvetica Neue, Helvetica, Verdana, Arial;
        display: block;
        .module-editor {
            display: flex;
            align-items: center;
        }
    }
}
.cms-module-operation-button {
    .module-editor {
        padding: 0 15px;
        border-radius: 4px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        height: 40px;
        line-height: 40px;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 5001;
        display: none;
        > .iconfont {
            cursor: pointer;
            text-align: center;
            height: 40px;
            line-height: 40px;
            display: inline-block;
            flex: 1;
            position: relative;
            color: #797e8b;
            font-size: 18px;
            margin-right: 15px;
            &:last-child {
                margin-right: 0;
            }
            &:hover{
                color: var(--primary-color);
            }
            &.icon-delete:hover {
                color: #f0454c;
            }
        }
        .icon-handle {
            cursor: move;
            font-size: 14px;
            margin-top: 2px;
        }
        .more-operation {
            display: flex;
            align-items: center;
            position: relative;
            padding: 0 0 0 15px;
            &:after {
                content: '';
                background-color: #ebecf1;
                width: 1px;
                height: 20px;
                position: absolute;
                left: 0;
            }
            .more-operation-list {
                .iconfont {
                    color: #797e8b;
                    font-size: 18px;
                    cursor: pointer;
                    &:hover {
                        color: var(--primary-color);
                    }
                }
                .more-list {
                    position: absolute;
                    width: 160px;
                    padding: 10px;
                    right: -15px;
                    border-radius: 2px;
                    background-color: #fff;
                    top: calc(100% + 4px);
                    li {
                        font-size: 0;
                        padding: 0px 10px;
                        cursor: pointer;
                        border-radius: 2px;
                        line-height: 32px;
                        height: 36px;
                        color: #515666;
                        text-align: left;
                        span {
                            font-size: 14px;
                            margin-left: 10px;
                        }
                        .iconfont {
                            font-size: 16px;
                            margin: 0;
                            &.icon-Vector:before{
                                transform: translateY(1px);
                                display: inline-block;
                            }
                        }
                        &:hover {
                            background: #f6f7fb;
                        }
                    }
                }
            }
        }
    }
}
</style>
