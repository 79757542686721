import util from '../../common/util.js';

export default {
    emits: ['after-enter'],
    inject: ['rootData', 'afterLeaveCb', 'destroy'],
    props: {
        closeOnClickModal: {
            type: Boolean,
            default: false
        },
        showMask: {
            type: Boolean,
            default: true
        },
        enableAnimation: {
            type: Boolean,
            default: true
        },
        animation: {
            type: String,
            default: 'fadeIn'
        },
        popMaskClass: {
            type: String,
            default: ''
        },
        customClass: {
            type: String,
            default: ''
        },
        fbLeadClass: {
            type: String,
            default: ''
        },
        allowClose: {
            type: Boolean,
            default: true
        },
        closeIcon: {
            type: String,
            default: 'icon-close'
        },
        //  use in special circumstances {visibility: show ? 'visible' : 'collapse'} replace v-show="show"
        visibilityMode: {
            type: Boolean,
            default: false
        },
        closeClass: {
            type: String,
            default: ''
        },
        onClose: Function,
        afterClose: Function
    },
    data: function() {
        return {
            show: false,
            needEnable: false,
            popStyle: {}
        };
    },
    watch: {
        'rootData.popShow': function (value) {
            if (value) {
                this.open();
            } else {
                this.hide();
            }
        }
    },
    created() {
        if (this.platform.isMobile) {
            this.popStyle = {
                position: 'absolute'
            };
        }
    },
    mounted() {
        // // document.body.appendChild(this.$el);
        // if (this.popShow){
        //     this.open();
        // }
    },
    methods: {
        clickMask() {
            if (this.closeOnClickModal) {
                this.destroy();
            }
        },
        open() {
            this.show = true;
            if (this.visibilityMode) {
                this.$emit('after-enter');
            }
        },
        hide() {
            this.show = false;
            if (this.visibilityMode) {
                this.afterLeaveCb();
            }
            this.afterClose && this.afterClose();
        },
        close() {
            this.hide();
            if (this.onClose) {
                this.onClose();
            }
            this.destroy(true);
        }
    },
    unmounted() {
        if (this.$el && this.$el.parentNode) {
            this.$el.parentNode.removeChild(this.$el);
        }
    }
};
